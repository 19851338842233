// .content-container{
//     left: 107px;
//     position: relative;
//     width: calc((100% - 107px));
//     padding: 25px;
//     background: #282731;
//     min-height: 100vh;
//     .btn{
//         width: 156px;
//         color: #fff;
//         background: #F4747E;
//         border-radius: 19px;
//         height: 35px;
//         position: relative;
//         border: none;
//         font-size: 1.2em;
//         text-align: left;
//         padding-left: 15px;
        
        
//         img{
//             width: 22px;
//             position: absolute;
//             right: 5px;
//             top: calc(50% - 11px);
//         }
//     }
//     .skeleton {
//         background-color: #282731
//     }
//     .arrow-right{
//         img{
//             transform: rotate(-90deg);
//         }
//     }
//     .search-wrapper{
//         width: 300px;
//         padding: 0 10px;
//         background: #3E3D46;
//         border-radius: 8px;
//         position: relative;
//         margin-left: 10px;
//         .search-icon{
//             height: 12px;
//             width: 15px;
//             position: absolute;
//             right: 10px;
//             top: calc(50% - 6px);
//         }
//         input{
//             border: none;
//             width: 100%;
//             height: 42px;
//             color: #fff;
//             background: transparent;
//         }


//     }


//     input::-webkit-calendar-picker-indicator {
//         opacity: 0;
//      }

//     .small-search-wrapper{
//         width: 157px !important;
//         padding: 0 10px;
//         background: #3E3D46;
//         border-radius: 8px;
//         position: relative;
//         margin-left: 0px !important;
//         .search-icon{
//             height: 12px;
//             width: 12px;
//             position: absolute;
//             right: 10px;
//             top: calc(50% - 6px);
//         }
//         input{
//             border: none;
//             width: 100%;
//             height: 42px;
//             color: #fff;
//             background: transparent;
//         }
//     }

        
//     .content-box{
//         background: #16161B;
//         padding: 20px;
//         border-radius: 8px;
//         display: inline-flex;
//     }
//     .info-label{
//         width: 100%;
//         display: flex;
//         flex-direction: column;
//         .label{
//             font-size: 1.2em;
//         }
//         .value{
//             font-size: 2em;
//         }
//     }
//     .pagination{
//         width: 100px;
//         border-radius: 30px;
//         background: #3E3D46;
//         align-items: center;
//         font-size: 1.2em;
//         justify-content: space-between;
//         padding: 2px;
//         margin-top: 20px;
//         .arrow{
//             &.left{
//                 transform: rotate(90deg);
//             }
//             &.right{
//                 transform: rotate(-90deg);
//             }
//             width: 25px;
//         }
//         img{
//             width: 100%;
//         }
//     }
// }
// .select-default{
//     display: flex;
//     align-items: center;
//     margin-right: 20px;
//     position: relative;
//     padding-right: 20px;

//     img{
//         width: 15px;
//         height: 10px;
//     }

//     &:last-of-type{
//         margin-right: 0;
//     }
        // &::after{
        //     content: '';
        //     width: 10px;
        //     height: 10px;
        //     background-image: url('/images/arrow-down.svg');
        //     background-size: cover;
        //     position: absolute;
        //     right: 0;
        // }
//     .select-label{
//         font-size: 1.2em;
//         color: #a0a0a0;
//         margin-right: 1em;
//     }
//     select{
//         appearance: none;
//         background: transparent;
//         background: url('/images/arrow-down-trick.svg') center right no-repeat;
//         background-size: 35px 10px;
//         border: none;
//         color: #fff;
//         outline: none;
//         font-size: 1.2em;
//     }

//     .container-options-select{
//         background-color: #3E3D46;
//         padding: .5em 3em .5em 1.5em;
//         border-radius: 8px;
//         text-align: left;
//         text-align-last: left;
//     }
// }

// .input-wrapper{
//     input{
//         background: #3E3D46;
//         width: 220px;
//         height: 27px;
//         border: none;
//         border-radius: 5px;
//         color: #fff;
//         outline: none;
//         padding: 0 15px;
//     }
// }

.content-container-mobile {
    background: #fcfafa;
    height: 100%;
}

@media screen and (min-width: 701px) {
    .content-container-mobile {
      display: none;
    }
}
